<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Calculate the value of
        <stemble-latex content="$\Delta\text{H}$" />
        for the following reaction:
      </p>
      <p class="mb-4 pl-6">
        <chemical-latex content="2 NO2(g) -> N2O4(g)" />
      </p>

      <p class="mb-3">
        based on the formation reactions for each of the reaction components as shown below:
      </p>

      <div class="mb-4 pl-6">
        <table>
          <tr>
            <td>
              <stemble-latex content="$\tfrac{1}{2}\ce{N2(g) + O2(g) -> NO2(g)}$" />
            </td>
            <td style="width: 50px" />
            <td>
              <stemble-latex content="$\Delta\text{H = 33.2 kJ/mol}$" />
            </td>
          </tr>
          <tr>
            <td>
              <chemical-latex content="N2(g) + 2 O2(g) -> N2O4(g)" />
            </td>
            <td style="width: 50px" />
            <td>
              <stemble-latex content="$\Delta\text{H}_2\text{ = 11.1 kJ/mol}$" />
            </td>
          </tr>
        </table>
      </div>

      <calculation-input
        v-model="inputs.reactionEnthalpy"
        prepend-text="$\Delta\text{H:}$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question80',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        reactionEnthalpy: null,
      },
    };
  },
};
</script>
